<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">予約一覧</h3>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">
      <el-form
          ref="form"
          :model="form"
          @submit.prevent.native="clickSearch"
      >
        <div class="row no-gutters">
          <div class="col-md-3" >
            <el-form-item style="display: flex" label="キーワード" class="search">
              <el-input  placeholder="予約番号、メールアドレス" v-model="form.keyword"></el-input>
            </el-form-item>
          </div>

          <div class="col-md-3" >
            <template>
              <el-form-item style="display: flex" label="ステータス" class="search">

              <el-select v-model="form.status" placeholder="ステータス">
                <el-option
                    v-for="item in statusOrder"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              </el-form-item>

            </template>
          </div>

          <div class="col-md-5" >
            <template>
              <el-form-item style="display: flex" label="利用期間" class="search">

            <el-date-picker
                v-model="form.time"
                type="daterange"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                value-format="yyyy-MM-dd"
                class="order-time"
            >
            </el-date-picker>
              </el-form-item>
            </template>

          </div>

          <div class="col-md-1" >
              <b-button class="d-flex flex-row" size="medium" @click="clickSearch" variant="primary">
                <b-icon icon="search" class="mr-1"></b-icon>
                検索
              </b-button>
          </div>
        </div>
      </el-form>
      <hr />
      <div class="d-flex flex-row align-items-center">
        <span class="demonstration">
          <my-pagination-top style="padding-left:8px;  padding-bottom:10px"
                             :total-record="parseInt(data.total)"
                             :current-page="parseInt(queryParam.page)"
                             :page-size-default="parseInt(data.per_page)"
                             @handleSizeChange="handleSizeChange"
          />
        </span>

      <div class="btn-order">
        <button class="el-button btn btn-primary el-button--text send-email-all router-link" :class="data.data === '' ? 'hidden' : ''" @click="getId" >メール送信</button>
        <button class="el-button btn btn-primary el-button--text csv router-link" :class="data.data === '' ? 'hidden' : ''" @click="exportCsv">CSV出力</button>
      </div>

      </div>
      <el-table
          class="table-custom"
          :fit="true"
          border
          :data="data.data"
          style="width: 100%"
          :row-class-name="tableRowClassName"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
      >
        <el-table-column type="selection" width="45" label="メール送信" align="center"></el-table-column>
        <el-table-column
            prop="status"
            label="ステータス"
            align="center"
            width="120"
           >
          <template slot-scope="scope">
            <div class="text-left">
              <span
                      v-if="scope.row.status == 1"
                      class="status-booked"
                      style="color: #00b300"
              >{{ $t('status_order.booked') }}</span>
              <span
                      v-else-if="scope.row.status == 2"
                      class="status-customer-cancel"
                      style="color: red"
              >{{ $t('status_order.customer_cancel') }}</span>
              <span
                      v-else-if="scope.row.status == 3"
                      class="status-done"
                      style="color: #8a6d3b"
              >{{ $t('status_order.done') }}</span>
              <span
                      v-else-if="scope.row.status == 4"
                      class="status-admin-cancel"
              >{{ $t('status_order.admin_cancel') }}</span>
              <span v-else class="status-pending" style="color: #f1c40f">{{ $t('status_order.pendding') }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            prop="code"
            label="予約番号"
            align="center"
            width="115"
        >
          <template slot-scope="scope">
            <div class="text-left">
              <el-link type="primary">
                <router-link
                        :to="{
                  name: 'order-detail',
                  params: { id: scope.row.id },
                }"
                >
                  {{ scope.row.code }}
                </router-link>
              </el-link>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="お名前"
            align="center">
          <template slot-scope="scope">
            <div class="text-left">
              <span >{{scope.row.customer.name}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="電話番号"
            align="center">
          <template slot-scope="scope">
            <div class="text-left">
              <span >{{scope.row.customer.phone}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="メールアドレス"
            align="center"
           >
          <template slot-scope="scope">
            <div class="text-left">
              <span >{{scope.row.customer.email}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="利用時間" align="center" width="110">
          <template slot-scope="scope">
            <div>
              <p>{{ scope.row.date }}</p>
              <p>{{ scope.row.booking_time }}</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            prop="updated_time"
            label="ステータス変更日"
            align="center"
            width="120">
        </el-table-column>

        <el-table-column
            label="利用金"
            align="center"
        width="100">
          <template slot-scope="scope">
            <div class="text-right">
              <p>{{formatNumber(scope.row.amount)}}円</p>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            width="200"
        >
          <template slot-scope="scope">
            <div class="row group-btn">
              <el-button
                  :disabled="scope.row.status === 2 || scope.row.status === 3 || scope.row.status === 4 "
                  :class="{'btn-disable': scope.row.status === 2  || scope.row.status === 3 || scope.row.status === 4 , 'hidden': scope.row.status === 5}"
                  class="btn router-link btn-cancel"
                  @click="cancelBooking(scope.row)"
                  type="text"
              >キャンセル</el-button>

              <el-button class="btn router-link btn-confirm"
                         :disabled="scope.row.status === 3 || scope.row.status === 4 "
                         :class="{'btn-disable': scope.row.status === 3  || scope.row.status === 4 , 'hidden': scope.row.status === 5 }"
                         @click="completeBooking(scope.row)"
                         type="text"
              >完了</el-button>
            </div>

          </template>
        </el-table-column>

      </el-table>
      <el-dialog
          title="キャンセル料が発生するかもしれませんが、予約をキャンセルしますか？"
          :visible.sync="dialogCancel"
          width="30%"
          center
      >
        <span slot="footer" >
          <div class="row">
            <div class="col-md-6 text-right">
                  <el-button class="btn-cancel-order" @click="dialogCancel = false">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left">
                  <el-button class="btn-confirm-order" type="primary" @click="comfirmCancelBooking()">はい</el-button>
            </div>
          </div>
        </span>
      </el-dialog>

      <el-dialog
          title="予約を完了しますか？"
          :visible.sync="dialogComplete"
          width="30%"
          center
      >
        <span slot="footer" >
          <div class="row">
            <div class="col-md-6 text-right" style="width: 120px">
                  <el-button class="btn-cancel-order" @click="dialogComplete = false">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left" style="width: 120px">
                  <el-button class="btn-confirm-order" type="primary" @click="comfirmCompleteBooking()">はい</el-button>
            </div>
          </div>
        </span>
      </el-dialog>

      <div v-if="data" class="row float-right pr-5 pt-7">
        <my-pagination
            :total-record="parseInt(data.total)"
            :currentPage="parseInt(data.current_page)"
            :page-size-default="parseInt(data.per_page)"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { LIST_ORDER, CHANGE_STATUS, CHANGE_STATUS_COMPLETE, EXPORT_CSV } from "@/core/services/store/order.module";
import { STATUS_CODE, STATUS_ORDER } from "@/core/config/constant";
import {CHECK_BOX} from "../../../core/services/store/auth.module";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ListOrder",
  data() {
    return {
      data: {},
      loading: false,
      dialogCancel: false,
      dialogComplete:false,
      ids:[],
      querySearch: {
        keyword: '',
        status:'',
        time:''
      },
      search: "",
      form: {
        keyword: '',
        status:'',
        time:'',
      },
      multipleSelection: [],
      select:[],
      statusOrder: STATUS_ORDER,
      formChangeStatus: {},
    };
  },

  computed: {
    ...mapGetters(["currentUser", "checkBox"]),
    queryParam() {
      return this.$route.query;
    },
  },

  watch: {
    queryParam() {
      this.getList();
    },
  },

  async created() {
    this.getDataFromQuery();
    this.getList();
  },

  methods: {
    ...mapActions([
      EXPORT_CSV,
    ]),

    async getList() {
      this.loading = true;
      let params = this.serialize(this.queryParam);
      if(params){
        params = params + '&orderByStatus=1'
      }
      else {
        params = 'orderByStatus=1'
      }
      let data = await this.$store.dispatch(LIST_ORDER, params);

      if (data.code == SUCCESS) {
        this.data = data.data
        let seft = this;
        if(this.checkBox.length){
          seft.select = seft.checkBox;

          let selection = seft.data.data.filter((row)=>{
            return seft.checkBox.includes(row.id)
          })
          setTimeout(function (){

            seft.toggleSelection(selection);
          },100)
        }

      }
      this.loading = false;
    },

    handleSizeChange(val) {
      this.$store.dispatch(CHECK_BOX,this.select)
      this.$router.push({
        name: "order",
        query: { ...this.queryParam, ...{ size: val, page: 1 } },
      });
    },

    handleCurrentChange(val) {
      this.$store.dispatch(CHECK_BOX,this.select)
      this.$router.push({
        name: "order",
        query: { ...this.queryParam, ...{ page: val } },
      });
    },

    clickSearch()
    {
      this.$router.push({
        name: "order",
        query: {...this.queryParam, ...this.form, ...{page: 1}}
      }).catch(err => {
        this.getList();
      });
    },

    getOverallIndex(index) {
      return (this.data.current_page * this.data.per_page) - this.data.per_page + (index + 1)
    },

    getDataFromQuery() {
      Object.keys(this.queryParam).map((value) => {
        if (value == "status" ) {
          if (this.queryParam[value].length > 0) {
            const data = parseInt(this.queryParam[value]);
            this.form[value] = data;
          }
        } else {
          this.form[value] = this.queryParam[value];
        }
      });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map((row)=>{
        return row.id
      });

       let selectNew = [...this.select, ...this.multipleSelection]
        let seft = this
        let list = this.data.data.filter((row)=>{
         return !seft.multipleSelection.includes(row.id)
       }).map((row)=>{
         return row.id
       })
      selectNew = selectNew.filter((row)=>{
        return !list.includes(row)
      })
       this.select = [...new Set(selectNew)];
    },

    getId(){
      if(this.select.length > 0){
        this.$router.push({ name: 'content-email', query: {flag: 0, id: this.select } })
      }
      else{
        this.notifyError('予約を選択してください。')
      }
    },

    async cancelBooking(val){
      this.dialogCancel = true;
      this.formChangeStatus.id = val.id;
      this.formChangeStatus.status = val.status;
      this.formChangeStatus.email = val.customer.email;
      this.formChangeStatus.name = val.customer.name;
      this.formChangeStatus.phone = val.customer.phone;
      this.formChangeStatus.code = val.code;
      this.formChangeStatus.date = val.date;
      this.formChangeStatus.time = val.booking_time;
      this.formChangeStatus.amount = val.amount;
    },

    async completeBooking(val){
      this.dialogComplete = true;
      this.formChangeStatus.id = val.id;
      this.formChangeStatus.status = val.status;
    },

    async comfirmCancelBooking(){
      this.loading = true;
      this.dialogCancel = false;
      const data = await this.$store.dispatch(CHANGE_STATUS, this.formChangeStatus );
      if (data.code == STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("success"));
        this.getList();
      }
      this.loading = false;
    },

    async comfirmCompleteBooking(){
      this.loading = true;
      const data = await this.$store.dispatch(CHANGE_STATUS_COMPLETE, this.formChangeStatus );
      if (data.code == STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("success"));
        this.dialogComplete = false;
        this.getList();
      }
      this.loading = false;
    },

    async exportCsv() {
      const dataEngineer = await this[EXPORT_CSV](
          this.serialize(this.form)
      );
      this.exportCsvCommon("予約一覧_", dataEngineer);
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      }
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  }

};

</script>
<style scoped>

::v-deep .search .el-form-item__content{
  width: 65%;
}
.send-email-all{
  background-color: #70B603 !important;
}
.btn-order{
  position: absolute;
  right: 10px ;
  padding-bottom: 12px;
}
.csv{
  margin-right: 20px
}
.group-btn{
  justify-content: center;
}
.btn-disable{
 background-color: #AAAAAA !important;
}
.btn-cancel{
  background-color: #F59A23;
  color: white;
}
.router-link{
  padding: 10px 10px !important;
  border: none !important;
}
.btn-confirm{
  background-color: #3699FF;
  color: white;
}
.btn-cancel-order{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
  width: 120px;
}
.order-time{
  width: 100%;
}
.btn-confirm-order{
  width: 120px;
}
.group-btn button{
  width: 88px;
}
::v-deep .table-custom .has-gutter th:first-child:before {
  content: "メール送信";
}

::v-deep .el-dialog__header{
  padding-top: 50px;
}
</style>
